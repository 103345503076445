<template>
  <div class="Login" id="Login" name="Login">
    <MyMenu :menuname="'other'"></MyMenu>
    <!-- 中部 -->
    <div class="Login_main">
      <div class="Login_main_w">
        <div class="Login_main_fl"><img src="https://file.juuzu.com/dllogo.jpg"></div>

        <div class="Login_main_rl">
          <login v-if="PathName == 'Login'"></login>
          <Register v-if="PathName == 'Register'"></Register>
        </div>
      </div>
    </div>
    <!-- 底部 -->

    <div class="Login_footer">
      <MyFooter></MyFooter>
    </div>
  </div>
</template>
<script>
import Login from './item/Login.vue'
import Register from './item/Register.vue'

export default {
  components: { Login, Register },
  data() {
    return {
      PathName: 'Login'
    }
  },
  watch: {},
  mounted() {
    this.$watch('$route', (val) => {
      // 监听路由变化
      this.PathName = val.name
    })
  },

  created() {},
  methods: {}
}
</script>
<style scoped>
.Login_header {
  width: 1200px;
  overflow: hidden;
  margin: 20px auto;
}
.Login_header img {
  width: 200px;
  float: left;
}
.Login_header ul {
  float: right;
  margin-top: 15px;
  font-size: 14px;
  color: #666;
}
.Login_header ul li {
  float: left;
  padding: 0 10px;
  line-height: 18px;
}
.Login_header ul li + li {
  border-left: 1px solid #666;
}

.Login_main {
  width: 100%;
  background: #fafafa;
}
.Login_main_w {
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
  overflow: hidden;
}
.Login_main_fl {
  width: 600px;
  float: left;
}
.Login_main_rl {
  width: 500px;
  background: #fff;
  float: right;
}
</style>
