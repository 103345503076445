<template>
  <div id="myLogin">
    <h2>用户登录</h2>
    <el-form :model="LoginUser" :rules="rules" status-icon ref="ruleForm" class="demo-ruleForm">
      <el-form-item prop="mobile">
        <el-input prefix-icon="el-icon-user-solid" placeholder="请输入手机号" v-model="LoginUser.mobile"></el-input>
      </el-form-item>
      <el-form-item prop="verCode">
        <el-input
          prefix-icon="el-icon-view"
          placeholder="请输入验证码"
          v-model="LoginUser.verCode"
        >
          <el-button slot="append" type="text" @click="getVerifyCode" :loading="downCode != 60"><span class="verify">{{downCode ==60 ? "获取验证码" :`${downCode}s` }}</span></el-button>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="medium" type="primary" @click="Login" style="width:100%;">登录</el-button>
      </el-form-item>
    </el-form>
    <el-divider>
      没有账号？
      <el-link type="primary" @click="Register">立即注册</el-link>
    </el-divider>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'MyLogin',
  data() {
    // 用户名的校验方法
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入用户名'))
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      const userNameRule = /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/
      if (userNameRule.test(value)) {
        this.$refs.ruleForm.validateField('checkPass')
        return callback()
      } else {
        return callback(new Error('字母开头,长度5-16之间,允许字母数字下划线'))
      }
    }
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入密码'))
      }
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      const passwordRule = /^[a-zA-Z]\w{5,17}$/
      if (passwordRule.test(value)) {
        this.$refs.ruleForm.validateField('checkPass')
        return callback()
      } else {
        return callback(new Error('字母开头,长度6-18之间,允许字母数字和下划线'))
      }
    }
    return {
      timer: null,
      downCode:60,
      LoginUser: {
        name: '',
        pass: ''
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        name: [{ validator: validateName, trigger: 'blur' }],
        pass: [{ validator: validatePass, trigger: 'blur' }]
      }
    }
  },
  computed: {},
  methods: {
    Register() {
      this.$router.push({ path: '/Register' })
    },
    ...mapActions(["setUser", "setToken","setShowLogin"]),
    Login() {

      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs["ruleForm"].validate(valid => {
        //如果通过校验开始登录
        if (valid) {
          let mobile = this.LoginUser.mobile
          this.$axios
            .post("/zuwu-api/official/login", {
              mobile: this.LoginUser.mobile,
              verCode: this.LoginUser.verCode
            })
            .then(({data}) => {
              if (data.code == 200) {
                // 隐藏登录组件
                this.isLogin = false;
                // 登录信息存到本地
                let user = "*******" + mobile.slice(-4);
                localStorage.setItem("user", user);
                // 登录信息存到vuex
                this.setUser(user);
                let token = data.data;
                localStorage.setItem("token", token);
                // 登录信息存到vuex
                this.setToken(token);
                // 弹出通知框提示登录成功信息
                this.$message.success(data.msg)
                this.$router.go(-1)
                // 清空输入框的校验状态
                this.$refs["ruleForm"].resetFields();
              } else {
                // 弹出通知框提示登录失败信息
                this.$message({
                  message: data.msg,
                  type: 'error'
                });
              }
            })
            .catch(err => {
              return Promise.reject(err);
            });
        } else {
          return false;
        }
      });
    },
    getVerifyCode(){
      let userNameRule = /^1[3-9]\d{9}$/
      if(userNameRule.test(this.LoginUser.mobile)){
        this.$axios.post("/zuwu-api/official/sms",{
          mobile:this.LoginUser.mobile
        }).then(({data})=>{
          if(data.code==200){
            this.$message.success('发送成功')
            this.timer =  setInterval(()=>{
              this.downCode--
              if(this.downCode==0){
                this.downCode = 60
                clearInterval(this.timer)
              }
            },1000)
          }else{
            this.$message({
              message: data.msg,
              type: 'warning'
            });
          }
        })
      }else{
        this.$message.warning('请输入正确的手机号')
      }
    }
  }
}
</script>
<style scoped>
#myLogin {
  padding: 20px 50px;
}
#myLogin h2 {
  color: #37a3ff;
  font-size: 18px;
  padding: 30px 0;
}
#myLogin /deep/ .el-input-group__append .el-button{
  width: 100px;
  background-color: #409EFF;
  color: #fff;
}
</style>
